import { render, staticRenderFns } from "./create-provider-media-modal.vue?vue&type=template&id=4dedf9fe&scoped=true"
import script from "./create-provider-media-modal.vue?vue&type=script&lang=js"
export * from "./create-provider-media-modal.vue?vue&type=script&lang=js"
import style0 from "./create-provider-media-modal.vue?vue&type=style&index=0&id=4dedf9fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dedf9fe",
  null
  
)

export default component.exports